import { AUTH_TYPE } from 'aws-appsync-auth-link';
import { createAuthLink } from 'aws-appsync-auth-link';

export const awsConfig: Parameters<typeof createAuthLink>[0] = {
  url: 'https://73ird36ixngvtnyqxktqvb5z3i.appsync-api.us-east-1.amazonaws.com/graphql', // || process.env.REACT_APP_AWS_ENDPOINT!,
  region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: 'da2-2rnagqp4bjgpbl63xme5sn5vra', //process.env.REACT_APP_AWS_APPSYNC_KEY!,
  },
};
